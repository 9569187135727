export const hasClass = (element, cls) => {
  return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1
};

export const addClass = (elem, classname) => {
  if (classname) {
    if (elem.classList) elem.classList.add(classname);
    else elem.className += ' ' + classname;
  }
};

export const toggleClass = (elem, className) => {
  if (elem.classList) {
    elem.classList.toggle(className);
  } else {
    const classes = el.className.split(' ');
    const existingIndex = classes.indexOf(className);
    
    if (existingIndex >= 0)
      classes.splice(existingIndex, 1);
    else
      classes.push(className);
    
    el.className = classes.join(' ');
  }
}


export const removeClass = (elem, classname) => {
  if (classname) {
    if (elem.classList) elem.classList.remove(classname);
    else elem.className = elem.className.replace(new RegExp('(^|\\b)' + classname.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }
};

export const domByClass = (instance, classname, first = false) => {
  let result;
  
  result = instance.getElementsByClassName(classname);
  
  if(first) {
    return result[0];
  } else {
    return result;
  }
  
};

export const arrayByClass = (instance, classname, first = false) => {
  const collection = instance.getElementsByClassName(classname);
  
  if(collection === undefined) {
    return collection;
  }
  
  let result;
  
  result = Array.from(collection);
  
  if (first) {
    return result[0];
  } else {
    return result;
  }
}

export const domById = (id) => {
  return document.getElementById(id);
};

export const closest = (el, selector) => {
  let matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  
  while (el) {
    if (matchesSelector.call(el, selector)) {
      break;
    }
    el = el.parentElement;
  }
  return el;
};

export const setStylesOnElement = function(styles, element){
  Object.assign(element.style, styles);
};