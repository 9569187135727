import { addClass, removeClass, closest, arrayByClass } from 'utils/dom';

export class WeberryModal {
  constructor (el) {
    
    if (el.WeberryModal) {
      return;
    }
    
    this.isOpened= false;
    this.isClosed = true;
    
    this.overlay = closest(el, '.js-modal-overlay');
    this.modal = el;
    
    this.processing = false;
    
    this.shown = false;
    this.animate = false;
    
    this._openTm = null;
    this._closeTm = null;
    
    this.registerOutsideClick();
    this.registerCloseBtn();
    
    this.modal.WeberryModal = this;
  }
  
  static preventScroll () {
    addClass(document.body, 'is-overflow');
  }
  
  static enableScroll () {
    removeClass(document.body, 'is-overflow');
  }
  
  open () {
    if(this.isOpened || this.processing) {
      return
    }
    
    this.isClosed = false;
    
    this.processing = true;
    
    WeberryModal.preventScroll();
    removeClass(this.overlay, 'is-hidden');
    this.shown = true;
    clearTimeout(this._openTm);
    this._openTm = setTimeout(() => {
      addClass(this.modal, 'is-animate');
      this.animate = true;
      
      this.isOpened = true;
      
      this.processing = false;
    }, 100);
  }
  
  close () {
    if(this.isClosed || this.processing) {
      return
    }
    
    this.isOpened = false;
    
    this.processing = true;
  
    removeClass(this.modal, 'is-animate');
    this.animate = false;
    clearTimeout(this._closeTm);
    this._closeTm = setTimeout(() => {
      addClass(this.overlay, 'is-hidden');
      this.shown = false;
      WeberryModal.enableScroll();
      
      this.isClosed = true;
      
      this.processing = false;
    }, 400);
  }
  
  
  registerOutsideClick() {
    const _self = this;
    const modal = this.modal;
    
    const fn = e => {
      if(!modal.contains(e.target)) {
        _self.close();
      }
    };
    
    this.overlay.addEventListener('click', fn);
  }
  
  registerCloseBtn() {
    const _self = this;
    
    const fn = () => _self.close();
    
    arrayByClass(this.modal, 'js-modal-close').forEach(btn => {
      btn.addEventListener('click', fn);
    })
    
  }
}