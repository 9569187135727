import {offset} from 'utils';

export function whichTransitionEvent(){
  let t,
    el = document.createElement("fakeelement");
  
  let transitions = {
    "transition"      : "transitionend",
    "OTransition"     : "oTransitionEnd",
    "MozTransition"   : "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  }
  
  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }
}

export function scrollOnLoad () {
  if(window.location.hash) {
    
    const target = document.getElementById(window.location.hash.substr(1));
    
    if(!target) {
      return false;
    }
    
    $('html, body').animate({
      scrollTop: offset(target).top - 20
    })
  }
}