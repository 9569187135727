const isProd = NODE_ENV === 'production';

import {arrayByClass} from 'utils/dom'

export const spriteSvgUpdate = (instance = document) => {
  
  const icons = arrayByClass(instance, 'icon-svg');
  
  icons.forEach(icon => {
    const use = icon.querySelector('use');
    use.setAttribute('xlink:href', use.getAttribute('xlink:href'));
  });
};

export const setResizeTimeout = (func, delay = 500) => {

  let timeout;

  const timeoutFunc = () => {
    clearTimeout(timeout);

    timeout = setTimeout(func, delay);
  };

  window.addEventListener('resize', timeoutFunc);

  return {
    destroy: () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', timeoutFunc);
    }
  }
};

export const setOrientationTimeout = (func, delay = 500) => {

  let timeout;

  const timeoutFunc = () => {
    clearTimeout(timeout);

    timeout = setTimeout(func, delay);
  };

  window.addEventListener('orientationchange', timeoutFunc);

  return {
    destroy: () => {
      clearTimeout(timeout);
      window.removeEventListener('orientationchange', timeoutFunc)
    }
  }
};


export const offset = (el) => {
  let rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
};

export const deviceOrientation = () => {
  let orientation = screen.msOrientation || (screen.orientation || screen.mozOrientation || {}).type;
  
  if (!orientation) {
    orientation = window.orientation === 180 || window.orientation === 0 ? 'portrait-primary' : 'landscape-primary';
  }
  
  return orientation;
};

export const calcWindowHeight = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

export const calcWindowWidth = () => Math.min(document.documentElement.clientWidth, window.innerWidth || 0);


export const isAnyPartOfElementInViewport = (el) => {
  
  const rect = el.getBoundingClientRect();
  
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
  
  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
  const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
  
  return (vertInView && horInView)
};

export const imgLoad = (img, params) => {
  
  const options = Object.assign({}, {
    img: null,
    onSuccess: () => {},
    onError: () => {}
  }, params);
  
  const {onSuccess, onError} = options;
  
  if(!img) {
    onError();
    return false;
  }
  
  if(img.complete) {
    onSuccess();
  }
  
  img.onload = () => {
    onSuccess();
  };
  
  img.onerror = () => {
    onError();
  }
};

export const onReady = (fn) => {
  if(isProd) {
    document.addEventListener('DOMContentLoaded', function (e) {
      fn();
    })
  } else {
    window.addEventListener('load', function () {
      fn();
    })
  }
};

export const declOfNum = (n, titles) => {
  return titles[(n%10===1 && n%100!==11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2)];
};