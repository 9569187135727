import 'select2/dist/js/select2.full.min.js';
import 'select2/dist/css/select2.min.css';
import {arrayByClass, addClass, removeClass } from "utils/dom";

function formatResult (state) {
  return $(
    '<span class="form-select-option">' +
    '<span class="form-select-option__text">' +  state.text + '</span>' +
    '</span>'
  );
}

function formatSelection (state) {

  return $(
    '<span class="form-select-button">' +
    '<span class="form-select-button__text">' +  state.text + '</span>' +
    '</span>'
  );
}

export const initSelect2 = () => {
  arrayByClass(document, 'js-select')
    .forEach(selectContainer => {
      const select = selectContainer.querySelector('select');
      select.selectedIndex = -1;
      $(select).select2({
        minimumResultsForSearch: -1,
        templateSelection: formatSelection,
        templateResult: formatResult,
        containerCssClass: '_form-select-container',
        dropdownCssClass: '_form-select-dropdown',
        theme: 'default',
        placeholder: '',
      });
      $(select).on('select2:open', function (e) {
        addClass(selectContainer, 'is-focused');
      });
      $(select).on('select2:close', function (e) {
        removeClass(selectContainer, 'is-focused');
      });
      $(select).on('select2:select', function (e) {
        addClass(selectContainer, 'is-selected');
      });
    });
};