let ajaxAuthObj = ajaxAuthObj || {

    events: function()
    {
        // Ajax request action
        $('#ajaxAuthForm').keydown(function(e)
        {
            if (e.keyCode === 13)
                return false;
        });
        $('#ajaxAuthForm button[type="button"]').click(function()
        {
            let $this = $(this),
                $form = $this.closest('form'),
                requestAction = 'ajaxAuth::' + (($this.data('submit-type')==='reg') ? 'onRegister' : 'onSignin');

            $('#ajaxAuthForm button[type="button"]').removeAttr('data-attach-loading');
            $this.attr('data-attach-loading','');
            $form.data('request', requestAction);
            $form.submit();
        });
    },

    init: function()
    {
        this.events();
    }
};

$(document).ready(function(){
    ajaxAuthObj.init();
});