import { WeberryModal } from 'modules/modal';
import { arrayByClass } from 'utils/dom';

const modalCol = arrayByClass(document, 'js-modal');

modalCol.forEach(modal => {
  new WeberryModal(modal);
});


const modalSuccessEl = document.getElementById('modalSuccess');
const modalErrorEl = document.getElementById('modalError');

const modalSuccess = {
  open: () => modalSuccessEl.WeberryModal.open(),
  close: () => modalSuccessEl.WeberryModal.close()
};

const modalError = {
  open: () => modalErrorEl.WeberryModal.open(),
  close: () => modalErrorEl.WeberryModal.close()
};

export {
  modalSuccess, modalError
};