import {initMap, addMarker} from "../modules/googleMap";
// import 'images/marker.svg';

if (document.getElementById('contactMap')) {

  const center = {lat: 34.065102, lng: -118.373372};

  let map = initMap('contactMap', {
    center: center,
    zoom: 16
  });

  const marker = addMarker({
    target: map,
    location: center,
    markerImg: 'themes/resources/assets/images/marker.svg',
    markerSize: new google.maps.Size(30, 44)
  });

  // let resizeEvt
  // $(window).bind('resize.gmap', function () {
  //   clearTimeout(resizeEvt);
  //   resizeEvt = setTimeout((function () {
  //     google.maps.event.trigger(map, 'resize');
  //     map.setCenter(location);
  //   }), 500);
  // });

}