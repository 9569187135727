// if(module.hot) {
//   module.hot.accept('./common/navbar', function () {
//     require('./common/navbar');
//   });
// }

// import 'imports-loader?define=>false!<module-name>'

const imagesContext = require.context('images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);

// import objectFitImages from 'object-fit-images';
// import lazyLoad from 'vanilla-lazyload';

// const myLazyLoad = new lazyLoad({
//   elements_selector: ".lazy img"
// });

// const example = () => {
//   return import(/* webpackChunkName: "carousel" */ './components/component/index.js');
// };