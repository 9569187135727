import './_app-common';

import 'plugins/jQuery';

import 'modules/browserDetect';

import 'components/ajaxauth';

import '../sass/main.scss';
import { onReady, calcWindowHeight, setResizeTimeout, setOrientationTimeout } from 'utils';
import { arrayByClass, addClass, removeClass } from "utils/dom";
import { initSelect2 } from "components/select";
import {WeberryCollapse} from "modules/collapse";
import breakpoints from 'utils/breakpoints';

import {modalSuccess, modalError} from 'components/modal';


const wbry_storage = {
  formSuccess: (form, context) => {
    form.reset();
    const elems = form.querySelectorAll('.js-form-input input, .js-form-input textarea');
  
    elems.forEach(el => {
      el.focus();
      el.blur();
    });
    
    modalSuccess.open();
  },
  formError: (form, context) => {
    modalError.open();
  }
};

window.wbry_storage = wbry_storage;

{
  onReady(() => {

    /* ---------------------------------index hero------------------------------------ */

    // const heroPrimaryContainer = document.querySelector('.js-hero-primary-container');
    // const heroResize = () => {
    //   if (heroPrimaryContainer && window.innerWidth >= breakpoints['xl']) {
    //     heroPrimaryContainer.style.minHeight = (calcWindowHeight() - document.querySelector('.js-navbar').clientHeight) + 'px';
    //   }
    // };
    //
    // heroResize();

    /* ---------------------------------forms elements------------------------------------ */

    // inputs
    arrayByClass(document, 'js-form-input')
      .forEach(formInputLabel => {
        const formInput = formInputLabel.querySelector('input, textarea');
        formInput.addEventListener('focus', function() {
          addClass(formInputLabel, 'is-focused');
        });
        formInput.addEventListener('blur', function() {          removeClass(formInputLabel, 'is-focused');
          if (this.value === '') addClass(formInputLabel, 'is-empty'); else removeClass(formInputLabel, 'is-empty');
        })
      });

    //selects
    initSelect2();

    /* ---------------------------------map------------------------------------ */

    require('components/map');
    
    /* ---------------------------------Modal------------------------------------ */
    require('components/modal');

    /* ---------------------------------navbar toggle sandwich collapse------------------------------------ */

    const navbarMenu = document.querySelector('.js-navbar-menu');

    const navbarMenuCollapseContainer = document.querySelector('.js-sandwich-collapse');
    const navbarMenuCollapse = new WeberryCollapse(navbarMenuCollapseContainer, {
      handlerShow() {
        if (window.innerWidth < breakpoints['md']) {
          navbarMenu.style.height = calcWindowHeight() + 'px';
        }
      },
      handlerShown() {
        addClass(document.body, 'fixed');
        addClass(document.documentElement, 'fixed');
      },
      handlerHidden() {
        removeClass(document.body, 'fixed');
        removeClass(document.documentElement, 'fixed');
        if (window.innerWidth < breakpoints['md']) {
          navbarMenu.style.height = '';
        }
      }
    });

    /* ---------------------------------resize handler------------------------------------ */
    
    /*
    const resizeFunc = () => {
    };

    if (window.bowser.isTouchable) {
      setOrientationTimeout(resizeFunc);
    } else {
      setResizeTimeout(resizeFunc);
    }
    */
  });
}